import React from 'react'
import './HomePage.css';
import Profile from './HomePageComponent/Profile';
import Content from './HomePageComponent/Content';

type Props = {}

const HomePage = (props: Props) => {
    return (
        <div id="HomePage">
            <Profile />
            <div className="border"></div>
            <Content />
        </div>
    )
}

export default HomePage