import React from 'react';
import './App.css';
import Header from './components/HeaderComponent/Header';
import HomePage from './components/Pages/HomePage/HomePage';
import BlogPage from './components/Pages/BlogPage/BlogPage';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        {/* ヘッダーを固定して、メインを90px下げる*/}
        <div className="mainContents">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/blog" element={<BlogPage />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
