import React from 'react'

type Props = {}

const BlogPage = (props: Props) => {
    return (
        <div className="container">
            <h1>ブログ</h1>
            <p>テスト</p>
        </div>
    )
}

export default BlogPage