import React from 'react'

type Props = {}

const Content = (props: Props) => {
    return (
        <div id="homePageContents">
            <p>コンテンツ追加予定</p>
        </div>
    )
}

export default Content