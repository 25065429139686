import React from 'react'
import { Link } from "react-router-dom"
import './Header.css';

type Props = {}

const Header = (props: Props) => {
  return (
    <header>
      <div className="logo">
        <h3>
          人間みならいの活動
        </h3>
      </div>
      <nav>
        <ul>
          <li>
            <Link to="/">ホーム</Link>
          </li>
          <li>
            <Link to="/blog">ブログ</Link>
          </li>
        </ul>
      </nav>
    </header>
  )
}

export default Header