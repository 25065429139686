import React from 'react'
import profileImage from "../HomePageImage/minarai.png";

type Props = {}

const Profile = (props: Props) => {
    return (
        <section id="about" className="wrapper">
            <div className="profileCard">
                <img src={profileImage} className="profileImage" alt="profileImage" />
                <div className="profileText">
                    <h3>にんげんみならい</h3>
                    <p>
                        人間みならいです。お茶好きでありながらIT系の会社員として生きてます。<br />
                        どう生きるか悩みながらも色々と活動してます。
                    </p>
                    <section id="sns" className="wrapper">
                        <nav>
                            <ul>
                                <li>
                                    <a href='https://twitter.com/pF1Muic366NUdZo'>茶人みならいのx</a>
                                </li>
                                <li>
                                    <a href='https://livewithdrink.com'>茶人みならいのお茶ブログ</a>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <a href='https://store.line.me/stickershop/author/4086998/ja'>みならいくんのlineスタンプ発売中です。</a>
                                </li>
                            </ul>
                        </nav>
                    </section>
                </div>
            </div>
        </section >
    )
}

export default Profile